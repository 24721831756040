import React from 'react'
import Layout from '../../components/Layout'
import StepCart from "../../components/StepCart"
import Input from "../../components/Input"
import Select from "../../components/Select"

export default function Register(props) {
    const { path } = props;
    return (
        <Layout>
            <div className="RegisterCart">
                <div className="Register-container">
                    <div className="row">
                        <div className="col-12">
                            <StepCart path={path} />
                            <div className="Register-card large">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="RegisterCart-title">
                                            Escribe tus datos personales
                                        </h3>
                                        <div className="RegisterCart-types">
                                            <label className="RegisterCart-radio" htmlFor="">
                                                <input type="radio" />
                                                Particular
                                            </label>
                                            <label className="RegisterCart-radio" htmlFor="">
                                                <input type="radio" />
                                                Empresa
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Input className="RegisterCart-input" label="Email" type="email" />
                                        <Input className="RegisterCart-input" label="Contraseña" type="password" />
                                        <Input className="RegisterCart-input" label="Nif" />
                                        <Input className="RegisterCart-input" label="Nombre" />
                                        <Select className="RegisterCart-select" label="Región" />
                                        <Input className="RegisterCart-input" label="Dirección" />
                                        <Input className="RegisterCart-input" label="Código Postal" />
                                        <Input className="RegisterCart-input" label="Localidad" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <Input className="RegisterCart-input recovery" label="Confirmar contraseña" type="password" />
                                        <Input className="RegisterCart-input lastname" label="Apellidos" />
                                        <Input className="RegisterCart-input apartment" label="Piso, puerta, escalera, apartamento..." />
                                        <Input className="RegisterCart-input phone" label="Teléfono" />
                                    </div>
                                    <div className="col-12">
                                        <label className="RegisterCart-message" htmlFor="">
                                            <input type="checkbox" />
                                            Estoy de acuerdo con la política de privacidad
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        <button className="RegisterCart-btn">Crear cuenta</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
